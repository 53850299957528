<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import Business from '@/services/Business'
    import Multiselect from 'vue-multiselect';
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import _ from 'underscore';
    // import validationMessages from '@/components/validations'
    export default {
        props: {
            modalData: {
                type: Object,
                default: () => ({}),
            },
        },
        //validationMessages
        components:{
            Multiselect,
            vueDropzone: vue2Dropzone,
        },
        validations: {
            filterInput: {
                crn_user:{
                   required,
                },
                email: {
                    required,
                },
                company:{
                    required,
                },
                name:{
                    required,
                },
                surname:{
                    required,
                },
                phone_number:{
                    required,
                }

            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                image_removed: false,
                file: null,
                image: "",
                photoDeletedCompanyImage:false,
                email : '',
                company_role:'',
                linkedin_url:'',
                title: "Customers",
                filterInput: {
                    crm_user: "",
                    company:"",
                    name:"",
                    surname:"",
                    phone_number:"",
                },
                customerData: [],
                selectedCompany:[],
                companyData:[],
                filter: null,
                isLoading: false,
                company_id:'',
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
            };
        },
        computed: {
            company() {
                return this.modalData && this.modalData.name ? this.modalData.name : '';
            }
        },
        watch: {

            'filterInput.crm_user': function(newVal) {
                this.filterInput.name = newVal && newVal.name ? newVal.name : '';
                this.filterInput.surname = newVal && newVal.surname ? newVal.surname : '';
                this.filterInput.email = newVal && newVal.email ? newVal.email : '';
                this.filterInput.phone_number = newVal && newVal.phone_number ? newVal.phone_number : '';
            },
        },
        methods: {

            async getCompanies(){
                try {
                    // this.toggleBusy();
                    const response = await Customers.getCompanies()
                    this.companyData = response.data.data
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.companyData = []
                }
            },
             getCustomersAutocomplete: _.debounce(async function(query) {

                this.customerData = [];
            if (!query || query.length < 3) return
                    this.isLoading = true;
                    try {
                        var suggestions = [];
                        const response = await Customers.getCustomersByFilter(`email=${query}`);
                        const rawData = response.data.data;
                        this.customerData = rawData
                            .map(item => ({
                                text: item.email,
                                id: item.customer_id,
                                name:item.first_name,
                                surname:item.last_name,
                                phone_number:item.phone_number,
                                email:item.email,
                            }))
                            .filter((value, index, self) =>
                                self.findIndex(x => x.id === value.id) === index
                            );

                    } catch (error) {
                        console.error("Error fetching customers:", error);
                    } finally {
                        this.isLoading = false;
                    }

            },500),

            selectFile(event) {
                if (this.image) {
                    URL.revokeObjectURL(this.image);
                }
                const file = event.target.files[0];
                this.file = file;
                this.image = URL.createObjectURL(file);
            },
            removeImage() {
                this.file = null;
                this.image = "";
                this.$refs['file-input'].reset()
                this.image_removed = true
            },
            selectFileProfileImage(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_profile_url.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one profile image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_profile_url.removeFile(value);
                        });
                    }
                    this.image = queuedFiles[0];

                    this.photoDeletedCompanyImage = false;
                }, 250);
            } ,

            async addBusinessMember() {
                if(this.modalData.company_id){
                    this.company_id = this.modalData.company_id;
                }else{
                    this.company_id = this.selectedCompany
                }
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()
                    try {
                        const customerId = this.filterInput.crm_user ? this.filterInput.crm_user.id : null;
                        if (customerId === null) {
                            return;
                        }
                        const data = {
                            company_id: this.company_id,
                            customer_id: customerId,
                            name:this.filterInput.name,
                            surname: this.filterInput.surname,
                            email:this.filterInput.email,
                            phone_number:this.filterInput.phone_number,
                            profile_url:this.image,
                            company_role:this.company_role,
                            linkedin_url:this.linkedin_url,
                        };

                        await Business.addBusinessUsers(data);
                        this.successmsg("Members has been saved");
                        this.closeModal();
                        this.refreshData();
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    } finally {
                        this.tryingToEdit = false;
                    }
            },
            clearAll () {
                this.customerData = []
                this.companyData = []
                this.selectedCompany= []
            },
            resetProps(){
                this.selectedCompany= [],
                this.crm_user = ''
                this.email= ''
                this.name = ''
                this.image = ''
                this.file = ''
                this.company_id=''
                this.company_role = ''
                this.linkedin_url = ''
                this.image_removed= false,
                this.customerData = []
                // this.companyData = []
                this.filterInput.crm_user = []
                this.filterInput.email = []
                this.filterInput.company = []
                this.filterInput.name = []
                this.submitted = false
            },
            refreshData() {
                this.$emit('onRefresh')
            },
            closeModal() {
                this.showModal = false;
            },
        },
        mounted() {
            this.getCompanies()
        }


    };
</script>
<template>
    <b-modal v-model="showModal" id="add_company_business_members" @hidden='resetProps()' title="Add Business Members" title-class="font-18" centered>
        <form @submit.prevent="addBusinessMember">
            <b-form-group>
                <label class="typo__label" for="ajax">Search CRM User</label>
                <multiselect v-model="filterInput.crm_user"
                                label="text"
                                track-by="id"
                                placeholder=""
                                :options="customerData"
                                :multiple="false"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="true"
                                @search-change="getCustomersAutocomplete"
                               >
                <template slot="singleLabel" slot-scope="{ option, remove }">
                <span class="custom__tag">
                    <span>{{ option.text }} | {{ option.id }}</span>
                    <span class="custom__remove" @click="remove(option)">✖</span>
                </span>
                </template>
                </multiselect>
                <label class="typo__label" for="ajax">Company Name</label>
                <b-form-input v-if="company" type="text" v-model="company" :disabled="company != ''"  required/>
                <b-form-select v-else v-model="selectedCompany">
                    <option value="" disabled>Select a company</option>
                    <option
                            v-for="company in companyData"
                            :key="company.company_id"
                            :value="company.company_id"
                    >
                        {{ company.name }}
                    </option>
                </b-form-select>
                <label class="typo__label" for="ajax">Name</label>
                <b-form-input type="text" v-model="filterInput.name" required/>

                <label class="typo__label" for="ajax">Surname</label>
                <b-form-input type="text" v-model="filterInput.surname" required/>

                <label class="typo__label" for="ajax">Email</label>
                <b-form-input type="text" v-model="filterInput.email" required/>

                <label class="typo__label" for="ajax">Phone</label>
                <b-form-input type="text" v-model="filterInput.phone_number" required/>

                <label class="typo__label" for="ajax">Company Role</label>
                <b-form-input type="text" v-model="company_role" required/>

                <label class="typo__label" for="ajax">LinkeIn URL</label>
                <b-form-input type="text" v-model="linkedin_url" required/>

                <b-form-group label="Profile Image" label-for="image" class="mb-3 image-area">
                    <template>
                        <vue-dropzone
                                id="profile_url"
                                ref="myVueDropzone_profile_url"
                                :use-custom-slot="true"
                                :options="dropzoneOptions_primary"
                                @vdropzone-files-added="selectFileProfileImage"
                                no-progress-bar
                        >
                            <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop file here or click to upload.</h4>
                            </div>
                        </vue-dropzone>
                    </template>
                </b-form-group>

            </b-form-group>
            <input  type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addBusinessMember" >
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>
