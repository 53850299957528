<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Business from '@/services/Business';
    import Customers from '@/services/Customers';
    import modalAddBusinessMember from "@/components/modals/business/modalAddBusinessMember";
    import modalAttachCompany from "@/components/modals/business/modalAttachCompany";
    import Swal from "sweetalert2";

    export default {
        components: { Layout, PageHeader, modalAddBusinessMember,modalAttachCompany   /*VueBootstrapTypeahead ,*/ },
        page: {
            title: "Business Users",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                isAuthorized: false,
                error: null,
                filterData: false,
                showMapData: false,
                showMap: false,
                tableData: [],
                title: "Business Users",
                items: [
                    {
                        text: "Business",
                        href: "/business/users",
                    },
                    {
                        text: "Business Users",
                        active: true,
                    },
                ],
                ticketing_client_types:[],
                userStatuses: [],
                filterInput: {
                    name: '',
                    gender: [],
                    company_id: '',
                    userStatus: [],
                },
                companyData: [],
                showModal: false,
                countries: [],
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                selectedUserData:null,
                userData:{},
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_on",
                sortDesc: true,
                fields: [
                    {
                        key: "profile_url",
                        label: "Profile Url",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Member Name",
                        sortable: true,
                    },
                    {
                        key: "email",
                        label: "Email",
                        sortable: true,
                    },
                    {
                        key: "phone_number",
                        label: "Phone Number",
                        sortable: true,
                    },
                    {
                        key: "company_name",
                        label: "Company Name",
                        sortable: true,
                    },
                    {
                        key: "company_role",
                        label: "Company Role",
                        sortable: true,
                    },
                    {
                        key: "company_logo",
                        label: "Company Logo",
                        sortable: true,
                    },

                    {
                        key: "linkedin_url",
                        label: "LinkedIn Profile",
                        sortable: true,
                    },
                    "action"
                ],
                modalData: {
                    name: "",
                    website: "",
                    description: "",
                    background_color: "",
                    logo_url: null,
                    company_image: null,
                },
            };
        },

        methods: {

            openAttachCompanyModal(data){
                this.selectedUserData = data;
                this.$bvModal.show('attach_company_modal')
            },
            callModalAddBusinessMembers(data) {
                this.modalData = { ...data };
                this.$bvModal.show("add_company_business_members");
            },

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            async detachFromCompany(customer_id,company_id) {
                Swal.fire({
                    title: "Detach Business member?",
                    text: "This Member will be detach from Company!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Detach!",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {

                            const response = await Customers.deleteBusinessMembers(company_id, customer_id);
                            if (response.data.success) {
                                await this.getBusinessMembers();
                                Swal.fire("Success!", "Business User is successfully detached from Company!", "success");
                            } else {
                                const error = response.error ? response.error : 'Failed';
                                Swal.fire("Fail!", error, "warning");
                            }
                        } catch (error) {
                            this.error = error.response && error.response.data && error.response.data.error
                                ? error.response.data.error
                                : "An error occurred while removing the member.";
                            Swal.fire("Error", this.error, "error");
                        }
                    }
                });
            },
            async removeMember(customer_id) {
                Swal.fire({
                    title: "Delete Business member?",
                    text: "This Member will be deleted!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Delete!",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const response = await Business.deleteBusinessUser(customer_id);
                            if (response.data.success) {
                                await this.getBusinessMembers();
                                Swal.fire("Success!", "Business User is successfully deleted!", "success");
                            } else {
                                const error = response.error ? response.error : 'Failed';
                                Swal.fire("Fail!", error, "warning");
                            }
                        } catch (error) {
                            this.error = error.response && error.response.data && error.response.data.error
                                ? error.response.data.error
                                : "An error occurred while removing the member.";
                            Swal.fire("Error", this.error, "error");
                        }
                    }
                });

            },

            async getBusinessMembers(){
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    // var filters = this.getFilters()
                    const response = await Business.getBusinessUsers();
                    this.tableData = response.data.users
                    this.totalRows = this.tableData.length
                } catch (error) {
                    // this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                } finally {
                    this.toggleBusy();
                }
            },

        },
        mounted() {
            this.getBusinessMembers();
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card" v-show="filterData">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col d-inline-flex align-items-center">
                                <h4 class="card-title m-0" style="margin-right: 15px !important;">Total Users: {{totalRows}}</h4>
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light me-3"
                                   @click="callModalAddBusinessMembers(tableData)">
                                    <i class="mdi mdi-plus me-2"></i>
                                    Add New Business User
                                </a>
                            </div>

                        </div>
                        <div class="row mt-4" v-show="filterData">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" v-show="filterData">
                            <b-table
                                    :items="tableData"
                                    :busy="isBusy"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >
                              <template v-slot:cell(profile_url)="data">
                                <img :src="data.item.profile_url" style="width:40px;"/>
                              </template>
                                <template v-slot:cell(name)="data">
                                    {{data.item.name}} {{data.item.surname}}
                                </template>
                                <template v-slot:cell(company_name)="data">
                                    <a :href="'/customers/companies/'+ data.item.company_id">{{data.item.company_name}}</a>
                                </template>
                                <template v-slot:cell(website)="data">
                                    <a :href="data.item.website " v-if="data.item.website">Website Url</a>
                                </template>
                                <template v-slot:cell(company_logo)="data">
                                  <img :src="data.item.company_logo" style="width:70px;"/>
                                </template>
                                <template v-slot:cell(linkedin_url)="data">
                                    <a :href="data.item.linkedin_url " v-if="data.item.linkedin_url">LinkedIn Profile</a>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <a href="javascript:void(0);" class="px-2 text-danger" title="Delete Business User" @click="removeMember(data.item.customer_id)">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                    </a>
                                    <a v-if="!data.item.company_id" href="javascript:void(0);" class="px-2 text-black" title="Attach Company" @click="openAttachCompanyModal(data.item)">
                                        <i class="uil uil-paperclip font-size-18"></i>
                                    </a>
                                    <a v-else href="javascript:void(0);" class="px-2 text-black" title="Detach from Company" @click="detachFromCompany(data.item.customer_id,data.item.company_id)">
                                        <i class="uil uil-link-broken font-size-18"></i>
                                    </a>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row" v-show="filterData">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modalAddBusinessMember
                :modalData="modalData"
            ></modalAddBusinessMember>
            <modalAttachCompany
               @onRefresh="getBusinessMembers()"
              :selectedUserData="selectedUserData"
            ></modalAttachCompany>
        </div>
    </Layout>
</template>
