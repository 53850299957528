
import Http from './Http'
import Security from './Security';

export default {
    async getBusinessWall() {
        return await Http.get(`business/wall`)
    },
    async updateBusinessWall(company_id,data){
        // let sanitizedData = Security.sanitizeObject(data)
        return  await  Http.patch(`business/wall/${company_id}`,data)
    },
    async addBusinessUsers(data) {
        return await Http.post(`/business/users`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async getBusinessMembers(){
        return await Http.get(`business/companies/members`)
    },
    async getBusinessUsers(){
        return await Http.get(`business/users`)
    },
    async addCompanyToBusinessWall(data) {
        return await Http.post(`/business/wall`, data)
    },
    async editBusinessMember(customer_id, data) {
        return await Http.post(`/business/users/${customer_id}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    async attachUserToCompany(data){
        return await Http.post(`business/companies/members`,data)
    },
    async deleteCompanyFromBusinessWall(company_id){
        return await Http.delete(`/business/wall/${company_id}`)
    },

    async deleteBusinessUser(customer_id){
        return await Http.delete(`/business/users/${customer_id}`)
    }
}


