<script>
import BusienssService from "@/services/Business";
import Customers from "@/services/Customers";

export default {
    props: {
        selectedUserData: {
            type: Object,
        }
    },
  data() {
    return {
      selectedCompany: {},
        companies:null,
        selectedUser:null,
    };
  },
  methods: {
      async getAllCompanies(){
          try {

              this.toggleBusy();

              const response = await Customers.getCompanies()
              this.companies = response.data.data
          } catch (error) {
              this.error = error.response.data.error ? error.response.data.error : "";
              this.companies = null
          } finally {
              this.toggleBusy();
          }
      },
    async saveChanges() {
        if (!this.selectedUserData || !this.selectedUserData.customer_id) {
            console.error("User data is missing.");
            return;
        }
      const payload = {
        company_id: this.selectedCompany,
        customer_id:this.selectedUserData.customer_id
      };
      try {
        const response = await BusienssService.attachUserToCompany(payload);
        if (response.status >= 200 && response.status < 300) {
          // Emit event for parent to update after save
          this.$emit('update-success', this.editableBusinessWall);
          this.$emit('onRefresh');  // Emit refreshTable only after saving
          this.$bvModal.hide('attach_company_modal');  // Close the modal after save
          // window.location.reload();

        } else {
          throw new Error('Failed to save changes');
        }
      } catch (error) {
        console.error("Error updating order:", error);
        alert("Error saving changes. Please try again.");
      }
    },

    closeModal() {
      // Only hide the modal, no page reload or table refresh here
      this.$bvModal.hide('attach_company_modal');
      this.selectedCompany={}
    },
  },
  async mounted(){
      this.getAllCompanies();
  }

};
</script>

<template>
  <b-modal id="attach_company_modal" title="Attach Company" @hidden="closeModal" size="xl" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group label="Select Company">
              <b-form-select
                      v-model="selectedCompany"
                      :options="companies.map(company => ({ text: company.name, value: company.company_id }))"
                      placeholder="Select a company">
              </b-form-select>

          </b-form-group>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="primary" @click="saveChanges">Save Changes</b-button>
    </template>
  </b-modal>
</template>
